import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import Template from '../components/Template'
import axios from 'axios'
import Select, { components } from 'react-select'
import { CURRENCY, ImagesUrl, ServerUrl, config, staffCode, styles } from '../components/Includes'
import TextEditor from '../components/editor'
import { ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify'

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

const CardDetails =()=> {

  const group  = useSelector((state:any) => state.rootReducer.group);
  let params = useParams()
  const [client, setClient] = useState({
    show_token:true,
    show_edit:false,
    payment_status:'',
    subject:'',
    html_body:'',
  });

  const [errors, setErrors] = useState({
   
    subject:'',
    html_body:'',
    errorMessage:'',
    successMessage:''
  });

  
  const handleEditorChange = (msg:any, editor:any) => {
    setClient({...client, html_body:msg});
  }

  const [content, setContent] = useState([] as any);


const handleDelete =()=>{

  let url = ServerUrl+'/card/delete/'+params.code;
 
  Swal.fire({
    title: 'Are you really sure?',
    text: "This is very dangerous, you shouldn't do it!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    showLoaderOnConfirm: true,
  preConfirm: async() => {
    return await axios.get(url, config).then(response=>{
     if(response.data.status!==200){
          throw new Error(response.statusText)
        }
        return response.data
      })
      .catch(error => {
        Swal.showValidationMessage(
          `Request failed: ${error}`
        )
      })
  },
  allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!'
  }).then((result:any) => {
    if (result.isConfirmed && result.value.status===200) {
     
       Swal.fire(
                  'Deleted!',
                  'Record has been deleted.',
                  'success'
                ) 

                setTimeout(() => {
                  
     window.open('/client/card_request', '_self')
                }, 3000);
    }
  })
}


const handleSendMail =(event: FormEvent<HTMLButtonElement>)=>{
  event.preventDefault();

  let error = {...errors}; 
  let formIsValid = true;

let msg ='This field is required';




if(!client.subject){
  error.subject =msg;
  formIsValid = false;
} 

  if(!client.html_body){
  error.html_body ='Mail content is required';
  formIsValid = false;
} 



if(!formIsValid){
  setErrors(error) 
  }
  
  
  if(formIsValid) {
  
    LoadingModal()
    const fd = new FormData();
    fd.append('subject', client.subject)
    fd.append('message', client.html_body)
    fd.append('email', content[0].email_address)
    fd.append('client_code', content[0].client_code)

         let url = ServerUrl+'/card/send_one_mail'
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
          SuccessModal(response.data.message)

          setTimeout(() => {
            window.location.reload()
          }, 3000);
               } else{

                ErrorModal('oops', JSON.stringify(response.data, null, 2))
               }   
             })
             .catch((error)=>{
            ErrorModal('oops', error.message)
             }) 
  }

}


  const fetchContent = async()=>{
    LoadingModal()
  
    let url = ServerUrl+'/card/show_one/'+params.code
    await  axios.get(url, config).then((result:any)=>{
             if(Array.isArray(result.data.data)){
            
               setContent(result.data.data)
               }else{
                ErrorModal('oops', 'Record not fount')
                window.open('/client/card_request', '_self')
               }
             })
             .catch((error)=>{
                 
          ErrorModal('oops', error.message)
             }).finally(()=>{
                 closeModal()
             })
             } 


       const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	

        if(name==='status'){
          value = value==='Active'?'InActive':'Active'
        }
        setClient({...client, [name]:value});
       setErrors({...errors, [name]:''});
     }

    


     const handleSelect = (option:any, action:any)=>{
      setClient({...client, [action.name]:option.value});
         }


         const handleUpdatePayment =(event: FormEvent<HTMLButtonElement>)=>{
          event.preventDefault();
        
          if(client.payment_status===''){
            ErrorModal('', 'Please select payment status')
          }else{

          
            LoadingModal()
            const fd = new FormData();
          
             
                 fd.append('field', 'payment_status')
                 fd.append('field_value', client.payment_status)
                 let url = ServerUrl+'/card/update/field/'+params.code;
                 axios.post(url, fd, config)
                 .then(response =>{
                 if(response.data.status === 200){
                  SuccessModal(response.data.message)
        
                setTimeout(() => {
                  window.location.reload()
                }, 300);
        
                       } else{
                       ErrorModal('', response.data.message)
                       }   
                     })
                     .catch((error)=>{
                    ErrorModal('oops', error.message)
                     }) 
          }
        }

   const handleUpdateStatus =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();
  
    
      LoadingModal()
      const fd = new FormData();
    
       
           fd.append('field', 'card_status')
           fd.append('field_value', 'Delivered')
           let url = ServerUrl+'/card/update/field/'+params.code;
           axios.post(url, fd, config)
           .then(response =>{
           if(response.data.status === 200){
            SuccessModal(response.data.message)
  
          fetchContent()
  
                 } else{
                 ErrorModal('', response.data.message)
                 }   
               })
               .catch((error)=>{
              ErrorModal('oops', error.message)
               }) 
    }

const handleUpdateField =()=>{

    const fd = new FormData();
         fd.append('field', 'is_new')
         fd.append('field_value', 'No')
         let url = ServerUrl+'/card/update/field/'+params.code;
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
         // SuccessModal(response.data.message)

               }   
             })
  }





          useEffect(()=>{
            fetchContent()
            handleUpdateField()
          }, [])

  return (<Template> 

{content&&content.map((list:any, index:number)=>
<main id="main" className="main" key={index}>

<div className="pagetitle">
  <h1>Card Details</h1>
  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item"><a href="/client/card_request">Card</a></li>
      <li className="breadcrumb-item active">{list.clientname}</li>
    </ol>
<div>

{group.includes('rl6')?
<button className='btn btn-danger ' onClick={handleDelete}> <i className="bi bi-trash"></i> Delete</button>:[]}&nbsp;

{group.includes('rl8')?
<button className='btn btn-success ' onClick={handleUpdateStatus}> <i className="bi bi-check2-circle"></i> Mark as Delivered</button>:[]}&nbsp;


    <a href='/client/card_request' className='btn btn-warning ' > <i className='bi bi-arrow-forward'></i>Back</a>

    </div>

  </nav>

  
</div>




<section className="section" key={index}>
  <div className="row">

    <div className="col-lg-12">

      <div className="card">

      <h5 className="card-header"> Card Details</h5>
        <div className="card-body ">
         

        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Preferred Name:</label>
                  <div className="col-sm-10">

                  <label className=" col-form-label">{list.fullname}</label>
                   
                    </div>
                </div>


        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Position Held:</label>
                  <div className="col-sm-10">
                  <label className=" col-form-label">{list.position}</label>
                  
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Delivery Location:</label>
                  <div className="col-sm-10">
                  <label className=" col-form-label">{list.location}</label>
                  </div>
                </div>

                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Delivery Address:</label>
                  <div className="col-sm-10">
                  <label className=" col-form-label">{list.address}</label>
                  </div>
                </div>
               


                <div className=" row mb-20">


<label className="col-sm-2 col-form-label">Additional Info:</label>

<div className="col-sm-10">

  <div className="input-group"><p>{list.comment} </p>
</div> 
</div>

</div>
<br/>
<div className="row">
<div className="col-md-2"></div>
  
  <div className="col-md-10">
  <h5>Company Logo</h5>


  <img className="img-100 p-image " id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}}  src={ImagesUrl+"/cards/"+list.photo}   />

 



  </div>
  
</div>

                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Client Name:<span className="text-danger">*</span></label>
                  <div className="col-sm-10">

                    <p>{list.clientname}</p>
                  </div>
                </div>

                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Client Email:<span className="text-danger">*</span></label>
                  <div className="col-sm-10">

                    <p>{list.email_address}</p>
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Payment Status:</label>
                  <div className="col-sm-10">

                   <span className={`badge rounded-pill ${list.payment_status==='Pending'?'bg-danger':list.payment_status==='Paid'?'bg-success':'bg-primary'}`} >{list.payment_status}</span>

                   {list.payment_status!=='Paid'? <button type='button'  className='btn btn-dark m-3' onClick={()=>setClient({...client, show_edit:!client.show_edit})}>Change</button>:[]}

                   {client.show_edit? <div className="col-sm-6 ">
                    <Select  options={[ 


          {value:'Pending', label:'Pending'},
          {value:'Processing', label:'Processing'}, 
          {value:'Paid', label:'Paid'},
          ]}   
          styles={styles}
onChange={handleSelect}  name="payment_status" value={{value:client.payment_status, label:client.payment_status}} 

/>

<button type='button' className='btn btn-warning' onClick={()=>setClient({...client, show_edit:!client.show_edit})}>Cancel</button>

<button type='button' className='btn btn-success m-2' onClick={handleUpdatePayment}>Update</button>

</div>:[]}
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Card Status</label>
                  <div className="col-sm-10">

                  <span className={`badge rounded-pill ${list.card_status==='Pending'?'bg-danger':list.card_status==='Processing'?'bg-primary':'bg-success'}`} >{list.card_status}</span>

                  
                  </div>
                </div>



                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Date Requested</label>
                  <div className="col-sm-10">

              
<label className=" col-form-label">{new Date(list.created_at).toLocaleString().slice(0,19)}</label>

                  
                  </div>
                </div>

        </div>
      </div>

    </div>
  </div>
</section>


			


{group.includes('rl1')?
<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">Send Message</h5>

<div className="card-body"> 



<div className="row mt-3">
						<section className="card-block">
                        

					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Email Subject</label>
		
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} placeholder=''  name="subject" value={content.subject} className={errors.subject? 'form-control form-control-danger ' : 'form-control '}/>

							</div>

            <span className='text-danger'>{errors.subject}</span>
							</div>
					</div>



                    <div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Email Message</label>

				
									
						<div className="col-sm-9 ">
            <div style={{ width: '100%'}}> 
            
            <TextEditor
            description={client.html_body}
            handleEditorChange ={handleEditorChange}
                height={300}        
              /> 
          
          <span className='text-danger'>{errors.html_body}</span>
                    </div>
							

                    <div className="form-group row mb-3 mt-3">
            
            <button className='btn btn-success ' onClick={handleSendMail} > <i className="bi bi-envelope"></i> Send Mail</button>
  </div>
							</div>
					</div>


       

	</section></div>
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>:[]}


</main>)}

    
    
</Template>
  )
}

export default CardDetails