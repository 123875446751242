import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import Template from '../components/Template'

import { useSelector } from 'react-redux';
import axios from 'axios'
import { ServerUrl, config, FrontUrl } from '../components/Includes'
import { usePaginated, DOTS } from '../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { ImagesUrl } from '../components/Includes';
import { CustomLoader, ErrorModal, LoadingModal, closeModal } from '../components/notify';

const Clients =()=> {


  const group  = useSelector((state:any) => state.rootReducer.group);
    const [loading, setLoading] = useState(false);
    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
 
    const [category, setCategory] = useState([] as any)
    const [search, setSearch]= useState({
      
      filterText:'',
        selectAll:false,
        totalSelected:0,

        expiry_date:'',
        firstname:'',
        country:'',
        company:'',
        username:'',
        email_address:'',
        status:'all'
       

    })

  
    const handleResetSearch =()=>{

      setSearch({
        filterText:'',
        selectAll:false,
        totalSelected:0,

        expiry_date:'',
        firstname:'',
        country:'',
        company:'',
        username:'',
        email_address:'',
        status:'all'
       })
  }

    const handleClear =()=>{
      setSearch({...search, filterText:''});
      setFilterContent(content)
  }


    const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	
      setSearch({...search, [name]:value});

      if(value!==''){
          const filteredItems = content.filter(
              (item:any) =>  item.firstname.toLowerCase().includes(value.toLowerCase()) ||
              item.username.toLowerCase().includes(value.toLowerCase()) ||
              item.email_address.toLowerCase().includes(value.toLowerCase()) ||
              item.lastname.includes(value),
          );

          setFilterContent(filteredItems)
      }else{
          setFilterContent(content)
      } 

   }

   const LoginAsClient =(row:any)=>{


    
  var inSixtyMinutes:any = Date.now() + 419 * 60 * 1000;
  var inOneMinutes:any = Date.now() + 418 * 60 * 1000;
    var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
    
     localStorage.setItem('_expt', inSixtyMinutes)
    localStorage.setItem('_bexpt', inOneMinutes) 


    /* 
  Cookies.set('etiucd', row.code, {expires: inOneHours, domain: domain  })
  Cookies.set('etitk', token, {expires: inOneHours, domain: domain  })  



            window.open(clientUrl+'/dashboard', '_blank') */

   }

    const handleSelectAll =(list:[])=>{
      
      let is_check = list.every((element:any) => {
       return element.is_check === 'true'
      });
      var i = 0;
          list.forEach((element:any) => {
            if(element.is_check === 'true')
           i++
          });
      setSearch({...search, selectAll:is_check, totalSelected:i})
      
         }

const handleCheck =(code:string)=>{
  const newContent = filterContent.map((item:any)=>{
     
  if(item.code === code){
  return {...item, 
      is_check:item.is_check==='false'?'true':'false',
  }
  };
  return item;
  })
  setFilterContent(newContent)
  handleSelectAll(newContent)
  }
  
  
  const handleSelectChange =()=>{
      let status = search.selectAll ===false?'true':'false';
      const newContent = filterContent.map((item:any)=>{
          return {...item, 
              is_check:status,
          }
    })
  
    var i = 0;
    newContent.forEach((element:any) => {
        if(element.is_check=== 'true')
       i++
      });
  
      setFilterContent(newContent)
  
      setSearch({...search, selectAll:!search.selectAll, totalSelected:i})
  
     }



     const DeleteModal =(row:any)=>{

      let url = ServerUrl+'/client/delete/'+row.code;
    
      Swal.fire({
        title: 'Are you really sure?',
        text: "This is very dangerous, you shouldn't do it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showLoaderOnConfirm: true,
      preConfirm: async() => {
        return await axios.get(url, config).then(response=>{
         if(response.data.status!==200){
              throw new Error(response.statusText)
            }
            return response.data
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading(),
        confirmButtonText: 'Yes, I am!'
      }).then((result:any) => {
        if (result.isConfirmed && result.value.status===200) {
         
          let rs =filterContent.filter((item:{id:number})=>item.id !==row.id)
              setContent(rs)
              setFilterContent(rs)
           Swal.fire(
                      'Deleted!',
                      'Record has been deleted.',
                      'success'
                    ) 
        }
      })
    }


    const fetchContent = async()=>{
  LoadingModal()

           const fd = new FormData();
           fd.append('company', search.company); 
           fd.append('firstname', search.firstname);
           fd.append('username', search.username);
           fd.append('email_address', search.email_address);
           fd.append('expiry_date', search.expiry_date);
           fd.append('status', search.status);
    

           let url = ServerUrl+'/clients/display/all'
         await  axios.post(url, fd, config).then((result:any)=>{
 
           if(Array.isArray(result.data.data) && result.data.data.length!==0){
          
               setFilterContent(result.data.data)
             setContent(result.data.data)
             }
           })
           .catch((error)=>{
               
        ErrorModal('oops', error.message)
           }).finally(()=>{
               closeModal()
           })
           } 


  

    const handleSelectOption = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});
           }
    
           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
         }

         const handleChangeSelect =(event:ChangeEvent<HTMLSelectElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
         }


         const handleSelect = (option:any, action:any)=>{
          setSearch({...search, [action.name]:option.value});
             }


         const TableData =()=>{

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40-h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                 {range}  </div>
            
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
            const CheckboxAll =()=>{ 
            
                return <div className="chk-option">
                <div className="checkbox-fade fade-in-primary">
                    <label className="check-task">
                
                     <input type="checkbox"  id="selectAll" checked={search.selectAll} onChange={handleSelectChange} />
                                
                    <span className="cr">
                        <i className="cr-icon fa fa-check txt-default"></i>
                    </span>
                    </label>
                </div>
                </div>}
            
            
            const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 
            
            return <div className="chk-option">
            <div className="checkbox-fade fade-in-primary">
                <label className="check-task">
            
                 <input type="checkbox"  name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)} /> 
                            
                <span className="cr">
                    <i className="cr-icon fa fa-check txt-default"></i>
                </span>
                </label>
            </div>
            </div>}
            
            
            
                        const columns = [
            
                            {
                                name: <CheckboxAll />,
                                cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
                                width:'60px'
                            },
                             {
                                name: 'Picture',
                                cell: (row:any) => 
                                <img  className="img-fluid img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/clients/${row.photo}`}  />
                           , 
                                sortable: true,
                                reorder:true,
                                width:'100px'
                            }, 
                            {
                              name: 'Name',
                              cell: (row:any) => row.prefix + ' '+row.firstname +' '+row.lastname, 
                              sortable: true,
                              reorder:true,
                              wrap:true,
            
                          },
                            {
                                name: `Country`,
                                selector: (row:any) => row.country,
                                sortable: true,
                                reorder:true,
                                width:'150px'
                            },
                            {
                                name: 'Username',
                                selector: (row:any) => row.username,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                            },
                            {
                              name: 'Email',
                              selector: (row:any) => row.email_address,
                              sortable: true,
                              reorder:true,
                              wrap:true,
                          },
                            {
                                name: 'Expire',
                                selector: (row:any) => row.expiry_date,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                            },

                            {
                                name: 'Company',
                                selector: (row:any) => row.company,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                            },
                            
                            {
                                 name: 'Action',
                                cell: (row:any, index:any, column:any, id:any) => <div className='col'>  
                                   
                                   <a href={FrontUrl+'/profile/'+row.username+'/'+row.code} className="btn btn-primary btn-sm " target='_blank'   ><i className='bi bi-eye'></i> </a>
                                   &nbsp;
                                   {group.includes('rl3')?   <a href={"/client/edit/"+row.code} className="btn btn-info btn-sm "   ><i className='bi bi-pencil'></i> </a>:[]}&nbsp;
            
            
                                   {group.includes('rl5')? <button type='button' className="btn btn-danger btn-sm" onClick={()=>DeleteModal(row)}><i className='bi bi-trash'></i> </button> :[]}
                                
                                </div>,
                                    left:true,
                                          
                                width:'200px'
                
                            },
                        ];
                        
                    
                    
                    
                        return <>
                        
            
                        <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    </>
                    }


                    const handleDeleteAll =()=>{

                      if(search.totalSelected<1){
                        ErrorModal('oops', 'Please select at least one record to delete ')
                      }else{
                    
                        let propertyList =[] as any;
                        let selectedProperty = filterContent.filter((item:any)=>item.is_check ==='true')
                    
                        if(Array.isArray(selectedProperty)){
                          selectedProperty.forEach((list:any) => {
                            propertyList.push(list.code)
                          });
                        }
                    
                        const fd = new FormData();
                        fd.append('propertyList', JSON.stringify(propertyList, null, 2));
                        let url = ServerUrl+'/property/delete_bulk'
                        Swal.fire({
                          title: 'Are you really sure?',
                          text: "This is very dangerous, you shouldn't do it!",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          showLoaderOnConfirm: true,
                        preConfirm: async() => {
                          return await axios.post(url, fd, config).then(response=>{
                           if(response.data.status!==200){
                                throw new Error(response.statusText)
                              }
                              return response.data
                            })
                            .catch(error => {
                              Swal.showValidationMessage(
                                `Request failed: ${error}`
                              )
                            })
                        },
                        allowOutsideClick: () => !Swal.isLoading(),
                          confirmButtonText: 'Yes, I am!'
                        }).then((result:any) => {
                          if (result.isConfirmed && result.value.status===200) {
                    
                            let rs =filterContent.filter((item:any)=>item.is_check ==='false')
                           setContent(rs)
                           setFilterContent(rs)
                             Swal.fire(
                                        'Deleted!',
                                        'Record has been deleted.',
                                        'success'
                                      ) 
                          }
                        })
                    
                      }
                    
                     }


            useEffect(()=>{
              fetchContent()
            }, [])

  return (<Template> 

<main id="main" className="main">
<div className="pagetitle">
  <h1>Clients</h1>
  <nav >
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item active"> Clients</li>
    </ol>
  </nav>
</div>








<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">Search Clients</h5>

<div className="card-body"> 
<div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row mb-3 mt-3">

                    <label className="col-sm-4 col-form-label">Client Name</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange} name="firstname" value={search.firstname} />
                        </div>
                    </div>


                    <div className="form-group row mb-3">
                    <label className="col-sm-4 col-form-label">Country</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange} name="country" value={search.country} />
                        </div>
                    </div>


                    <div className="form-group row mb-3">
                      <label className="col-sm-4 col-form-label">Expiry Date</label>
                                    
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control" onChange={handleChange} name="expiry_date" value={search.expiry_date} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group row mb-3 mt-3">
                <label className="col-sm-4 col-form-label">Username</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="username" value={search.username} />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                    <label className="col-sm-4 col-form-label">Email Address</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="email_address" value={search.email_address} />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                    <label className="col-sm-4 col-form-label">Client Status</label>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="status" value={search.status} className="form-control" onChange={handleChangeSelect}>
                        <option value="all">All</option>
                        <option value="Yes">Active</option>
                        <option value="No">In Active</option>
                    </select>
                </div>
                    </div>
                </div>
                </div>
            


            <div className="row mt-3"> 
            
            <div className="col-lg-7 d-flex justify-content-end">
           

            <button type="button" className="btn btn-secondary  " onClick={handleResetSearch}  ><i className="bi bi-arrow-clockwise"></i> Clear</button>&nbsp;
	<button type="button" className="btn btn-primary " onClick={fetchContent}  ><i className="bi bi-search"></i> Search</button>
   


    </div>
            
            </div>
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>










            <section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">All Clients</h5>

<div className="card-body"> 


<div className="row mt-3 mb-3">

<div className=" col-md-8 ">
  
   <button type="button" className="btn btn-outline-danger btn-sm " onClick={handleDeleteAll} ><i className="bi bi-trash"></i> Delete Selected ({search.totalSelected})</button>
  &nbsp;


   <a href='/client/add' className="btn btn-outline-success btn-sm " ><i className="bi bi-add"></i> Add New</a>
  


                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-secondary " >
                          <i className="bi bi-arrow-repeat"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>


<TableData />
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>
                            </main>
    </Template>
  )
}

export default Clients