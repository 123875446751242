import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import Template from '../components/Template'

import Select, { components } from 'react-select'
import axios from 'axios'
import { CURRENCY, ServerUrl, config, staffCode, styles } from '../components/Includes'
import { usePaginated, DOTS } from '../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { ImagesUrl } from '../components/Includes';
import { CustomLoader, ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify';
import { FormatNumber } from '../components/GlobalFunction'

import { useSelector } from 'react-redux';

const Items =()=> {


  const group  = useSelector((state:any) => state.rootReducer.group);
    const [loading, setLoading] = useState(false);
    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
 
    const [category, setCategory] = useState({
      code:'',
      title:'',
      link:'',
      imageFile:'',
      filePath:'', 
      passport:'', 
      photo:'',
      placeholder:'',
    })
    const [search, setSearch]= useState({
      is_active:'',
      email:'',
      filterText:'',
      fullname:'',
        selectAll:false,
        totalSelected:0,
        group:[] as any,
       

    })
    const [errors, setErrors] = useState({
      title:'',
     
    });
  
    const handleResetSearch =()=>{

      setSearch({
        is_active:'',
        email:'',
        fullname:'',
        filterText:'',
          selectAll:false,
          totalSelected:0,
          group:[] as any,
       })
  }

    const handleClear =()=>{
      setSearch({...search, filterText:''});
      setFilterContent(content)
  }


  const  handleFile = function(fieldName:any){
    return function(newValue:any){                 
   readURL(newValue);
   }
  } 
  const readURL = (input:any)=>{

    let displayMessage = '';
    
    const PreviewImage = input.target.name;
    const inputElement = document.getElementById(PreviewImage) as HTMLInputElement
    const fileName = inputElement.value
    
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = ' File uploaded is larger than maximum size Allow (500 kb).';
        inputElement.value = '';
     
        setCategory({...category, filePath:'', imageFile:''})
    
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            var reader = new FileReader();//Initialize FileReader.
            reader.onload = function (e:any) {


            //Check if input has files.
            let Id = Math.random().toString(36).substring(2, 9)
             let fileUrl = Id+'.png'
    
             setCategory({...category,
                filePath:fileUrl, 
                passport:e.target.result, 
                imageFile:input.target.files[0]})
    
            }
            reader.readAsDataURL(input.target.files[0]);
            
        }
    }
    }else{
    displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 500 kb'
    inputElement.value = '';
    setContent({...content, filePath:'', imagesFile:''})
    }   
    
    if(displayMessage.length > 0){
   ErrorModal("", displayMessage)
    }
    }

    const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	
      setSearch({...search, [name]:value});

      if(value!==''){
          const filteredItems = content.filter(
              (item:any) => item.code.toLowerCase().includes(value.toLowerCase()) || 
              item.title.toLowerCase().includes(value.toLowerCase()) 
          );

          setFilterContent(filteredItems)
      }else{
          setFilterContent(content)
      } 

   }




     const DeleteModal =(row:any)=>{

      let url = ServerUrl+'/item/delete/'+row.code;
     
      Swal.fire({
        title: 'Are you really sure?',
        text: "This is very dangerous, you shouldn't do it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showLoaderOnConfirm: true,
      preConfirm: async() => {
        return await axios.get(url, config).then(response=>{
         if(response.data.status!==200){
              throw new Error(response.statusText)
            }
            return response.data
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading(),
        confirmButtonText: 'Yes, I am!'
      }).then((result:any) => {
        if (result.isConfirmed && result.value.status===200) {
         
          let rs =filterContent.filter((item:{id:number})=>item.id !==row.id)
              setContent(rs)
              setFilterContent(rs)
           Swal.fire(
                      'Deleted!',
                      'Record has been deleted.',
                      'success'
                    ) 
        }
      })
    }


const handleEdit =(item:any)=>{
  setCategory({
    ...category,
    title:item.title,
    link:item.link,
    code:item.code,
    photo:item.photo,
    placeholder:item.placeholder
   })
   var doc = document.getElementById('openupdate')
   doc?.click()
}


    const handleSubmit =(event: FormEvent<HTMLButtonElement>, url:string, closeIt:string)=>{
      event.preventDefault();
    
      let error = {...errors}; 
      let formIsValid = true;
    
    let msg ='This field is required';
   

    if(!category.title){
      error.title =msg;
      formIsValid = false;
    } 

    
  
    if(!formIsValid){
      setErrors(error) 
      }
      
      
      if(formIsValid) {
      
        LoadingModal()
        const fd = new FormData();
       
             if(category.imageFile){
            fd.append('image', category.imageFile)
             }
             fd.append('title', category.title)
             fd.append('link', category.link)
             fd.append('placeholder', category.placeholder)
             fd.append('photo', category.photo)
            // let url = ServerUrl+'/item/add';
             axios.post(url, fd, config)
             .then(response =>{

              //console.log(response.data)
             if(response.data.status === 200){
              SuccessModal(response.data.message)
             setCategory({
              title:'',
              imageFile:'',
              filePath:'', 
              passport:'', 
              link:'',
              placeholder:'',
              code:'',
              photo:''
             })
             var doc = document.getElementById(closeIt)
             doc?.click()
        
             fetchContent()
                   } else{
                   ErrorModal('', response.data.message)
                   }   
                 })
                 .catch((error)=>{
                ErrorModal('oops', error.message)
             
                 }) 
      }
  
  }


    const fetchContent = async()=>{
  LoadingModal()

  let url = ServerUrl+'/items/display/all'
  await  axios.get(url, config).then((result:any)=>{
 
           if(Array.isArray(result.data.data) && result.data.data.length!==0){
          
               setFilterContent(result.data.data)
             setContent(result.data.data)
             }
           })
           .catch((error)=>{
               
        ErrorModal('oops', error.message)
           }).finally(()=>{
               closeModal()
           })
           } 


           const handleChangeCat =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	

            if(name==='link'){
              value = value.replace(/[\s;,?%0-9]/, '')
            }
            setCategory({...category, [name]:value});
            setErrors({...errors, [name]:''});
         }
    

         const TableData =()=>{

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40-h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                 {range}  </div>
            
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
                        const columns = [
            
                             {
                                name: 'Picture',
                                cell: (row:any) => 
                                <img  className="img-fluid img-80" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/items/${row.photo}`}  />
                           , 
                                sortable: true,
                                reorder:true,
                                width:'100px'
                            }, 
                            {
                              name: 'Title',
                              cell: (row:any) => row.title, 
                              sortable: true,
                              reorder:true,
                              wrap:true,
            
                          },
                          {
                            name: 'Link',
                            cell: (row:any) => row.link, 
                            sortable: true,
                            reorder:true,
                            wrap:true,
          
                        },
                        {
                          name: 'Placeholder',
                          cell: (row:any) => row.placeholder, 
                          sortable: true,
                          reorder:true,
                          wrap:true,
        
                      },
                            {
                                 name: 'Action',
                                cell: (row:any, index:any, column:any, id:any) => <div className='col'>  
                                   
                                     <button type='button' onClick={()=>handleEdit(row)} className="btn btn-info btn-sm "   ><i className='bi bi-pencil'></i> </button>&nbsp;
            
            
                             <button type='button' className="btn btn-danger btn-sm" onClick={()=>DeleteModal(row)}><i className='bi bi-trash'></i> </button> 
                                
                                </div>,
                                    left:true,
                                          
                
                            },
                        ];
                        
                    
                    
                    
                        return <>
                        
            
                        <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    </>
                    }



            useEffect(()=>{
              fetchContent()
            }, [])

  return (<Template> 
{group.includes('rl3')?
<main id="main" className="main">
<div className="pagetitle">
  <h1>Items</h1>
  <nav >
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item active"> Items</li>
    </ol>
  </nav>
</div>











            <section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">All Items</h5>

<div className="card-body"> 




<div className="modal fade" id="addcategory" tabIndex={-1} aria-modal="true" role="dialog" >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add New Item</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                    <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Title<span className="text-danger">*</span></label>
                  <div className="col-sm-10">
                    <input type="text" name="title" value={category.title} onChange={handleChangeCat} className={errors.title?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  />
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Link<span className="text-danger">*</span></label>
                  <div className="col-sm-10">
                    <input type="url" name="link" value={category.link} onChange={handleChangeCat} className="form-control form-control-lg"  />
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Placeholder</label>
                  <div className="col-sm-10">
                    <input type="text" name="placeholder" value={category.placeholder} onChange={handleChangeCat} className="form-control form-control-lg"  />
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Picture</label>
                  <div className="col-sm-10">


                  <input type="file" className='form-control form-control-lg' onChange={handleFile('photo')} accept="image/*" name="photo" id="photo" />




                  </div>
                </div>

                     
                    </div>
                    <div className="modal-footer">
                      <button type="button" id='closeIt' className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={(e)=>handleSubmit(e, ServerUrl+'/item/add', 'closeIt')}  >Submit</button>
                    </div>
                  </div>
                </div>
              </div>



              <a  href='#' id='openupdate' data-bs-toggle="modal" data-bs-target="#update-item">

</a>
              <div className="modal fade" id="update-item" tabIndex={-1} aria-modal="true" role="dialog" >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content ">
                    <div className="modal-header">
                      <h5 className="modal-title">Update Item</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                    <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Title<span className="text-danger">*</span></label>
                  <div className="col-sm-10">
                    <input type="text" name="title" value={category.title} onChange={handleChangeCat} className={errors.title?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  />
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Placeholder</label>
                  <div className="col-sm-10">
                    <input type="text" name="placeholder" value={category.placeholder} onChange={handleChangeCat} className="form-control form-control-lg"  />
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Link<span className="text-danger">*</span></label>
                  <div className="col-sm-10">
                    <input type="url" name="link" value={category.link} onChange={handleChangeCat} className="form-control form-control-lg"  />
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Picture</label>
                  <div className="col-sm-10">


                  <input type="file" className='form-control form-control-lg' onChange={handleFile('image')} accept="image/*" name="image" id="image" />




                  </div>
                </div>

                     
                    </div>
                    <div className="modal-footer">
                      <button type="button" id='close' className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, ServerUrl+'/item/update/'+category.code, 'close')} >Update</button>
                    </div>
                  </div>
                </div>
              </div>

<div className="row mt-3 mb-3">

<div className=" col-md-8 ">
  
<button type="button" className="btn btn-outline-success btn-sm " data-bs-toggle="modal" data-bs-target="#addcategory">
Add New Item
              </button>


            

  
                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-secondary " >
                          <i className="bi bi-arrow-repeat"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>


<TableData />
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>
                            </main>:[]}
    </Template>
  )
}

export default Items