import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import Template from '../components/Template'

import Select, { components } from 'react-select'
import axios from 'axios'
import { CURRENCY, ServerUrl, config, staffCode, styles } from '../components/Includes'
import { usePaginated, DOTS } from '../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { ImagesUrl } from '../components/Includes';
import { CustomLoader, ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify';
import { FormatNumber } from '../components/GlobalFunction'

const EmailTemplate =()=> {


    const [loading, setLoading] = useState(false);
    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
 
    const [category, setCategory] = useState({
      code:'',
      title:'',
      link:'',
      imageFile:'',
      filePath:'', 
      passport:'', 
      photo:'',
      placeholder:'',
    })
    const [search, setSearch]= useState({
      is_active:'',
      email:'',
      filterText:'',
      fullname:'',
        selectAll:false,
        totalSelected:0,
        group:[] as any,
       

    })
   
  

    const handleClear =()=>{
      setSearch({...search, filterText:''});
      setFilterContent(content)
  }


    const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	
      setSearch({...search, [name]:value});

      if(value!==''){
          const filteredItems = content.filter(
              (item:any) => item.code.toLowerCase().includes(value.toLowerCase()) || 
              item.template_name.toLowerCase().includes(value.toLowerCase()) 
          );

          setFilterContent(filteredItems)
      }else{
          setFilterContent(content)
      } 

   }




const handleEdit =(item:any)=>{
  setCategory({
    ...category,
    title:item.title,
    link:item.link,
    code:item.code,
    photo:item.photo,
    placeholder:item.placeholder
   })
   var doc = document.getElementById('openupdate')
   doc?.click()
}





    const fetchContent = async()=>{
  LoadingModal()

  let url = ServerUrl+'/setup/message_template/view'
  await  axios.get(url, config).then((result:any)=>{
 
           if(Array.isArray(result.data.data) && result.data.data.length!==0){
          
               setFilterContent(result.data.data)
             setContent(result.data.data)
             }
           })
           .catch((error)=>{
               
        ErrorModal('oops', error.message)
           }).finally(()=>{
               closeModal()
           })
           } 


 
    

         const TableData =()=>{

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40-h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                 {range}  </div>
            
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
                        const columns = [
                          {
                            name: 'SN',
                            cell: (row:any, index:number) => index+1, 
                            sortable: true,
                            reorder:true,
                            width:'100px'
          
                        },
                            {
                              name: 'Template Name',
                              cell: (row:any) => row.template_name, 
                              sortable: true,
                              reorder:true,
                              wrap:true,
            
                          },
                          {
                            name: 'Email',
                            cell: (row:any) => row.is_email, 
                            sortable: true,
                            reorder:true,
                            wrap:true,
          
                        },
                            {
                                 name: 'Action',
                                cell: (row:any, index:any, column:any, id:any) => <div className='col'>  
                                   
                                     <a href={'/systems/email_template/edit/'+row.code} className="btn btn-warning btn-sm "   ><i className='bi bi-pencil'></i> Edit </a>&nbsp;
            
          
                                </div>,
                                    left:true,
                                          
                
                            },
                        ];
                        
                    
                    
                    
                        return <>
                        
            
                        <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    </>
                    }



            useEffect(()=>{
              fetchContent()
            }, [])

  return (<Template> 

<main id="main" className="main">
<div className="pagetitle">
  <h1>Email Template</h1>
  <nav >
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item active"> Template</li>
    </ol>
  </nav>
</div>











            <section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">All Items</h5>

<div className="card-body"> 



<div className="row mt-3 mb-3">


    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-secondary " >
                          <i className="bi bi-arrow-repeat"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>


<TableData />
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>
                            </main>
    </Template>
  )
}

export default EmailTemplate