
import { useSelector } from 'react-redux';
const Sidebar =()=> {
  
  const group  = useSelector((state:any) => state.rootReducer.group);



  return (  
    <aside id="sidebar" className="sidebar">
    
      <ul className="sidebar-nav" id="sidebar-nav">
    
        <li className="nav-item">
          <a className="nav-link collapsed" href='/dashboard' >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </a>
        </li>
       

        {group.includes('rl3')?  <li className="nav-item">
     
          <a className="nav-link collapsed" href='/items' >
            <i className="bi bi-menu-button-wide"></i>
            <span>Items</span>

          </a>
        </li>:[]}

        <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#products" data-bs-toggle="collapse" href="#">
            <i className="bi bi-journal-text"></i><span>Clients</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
        {group.includes('rl2')?  <ul id="products" className="nav-content collapse " data-bs-parent="#sidebar-nav">
             <li>
              <a href='/client/add'>
                <i className="bi bi-circle"></i><span>Add a new Clients</span>
              </a>
            </li>
            <li>
              <a href='/clients'>
                <i className="bi bi-circle"></i><span>View All Clients</span>
              </a>
            </li>
            <li>
              <a href='/client/card_request'>
                <i className="bi bi-circle"></i><span>Card Request</span>
              </a>
            </li>
          </ul>:[]}
        </li>

      

        



        <li className="nav-heading">Setup</li>


      {group.includes('rl1')?  <li className="nav-item">
          <a className="nav-link collapsed" href='/send_message' >
            <i className="bi bi-envelope"></i>
            <span>Messages</span>
          </a>
        </li>:[]}


        {group.includes('rl12')? <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#users" data-bs-toggle="collapse" href="#">
            <i className="bi bi-layout-text-window-reverse"></i><span>Users</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="users" className="nav-content collapse " data-bs-parent="#sidebar-nav">
            <li>
              <a href='/user/add'>
                <i className="bi bi-circle"></i><span>Add New User</span>
              </a>
            </li>
            <li>
              <a href='/users'>
                <i className="bi bi-circle"></i><span>View All User</span>
              </a>
            </li>
            <li>
              <a href='/user/group'>
                <i className="bi bi-circle"></i><span>User Group</span>
              </a>
            </li>
            
            <li>
              <a href='/user/referer'>
                <i className="bi bi-circle"></i><span>Referer</span>
              </a>
            </li>
            
          </ul>
        </li>:[]}


        <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#systems" data-bs-toggle="collapse" href="#">
            <i className="bi bi-gear"></i><span>Systems</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="systems" className="nav-content collapse " data-bs-parent="#sidebar-nav">
          
          {group.includes('rl14')? <li>
              <a href='/setup'>
                <i className="bi bi-circle"></i><span>Basic Details</span>
              </a>
            </li>:[]}
            {group.includes('rl11')?  <li>
              <a href='/systems/location'>
                <i className="bi bi-circle"></i><span>Location Price</span>
              </a>
            </li>:[]}
            {group.includes('rl13')? <li>
              <a href='/systems/email_template'>
                <i className="bi bi-circle"></i><span>Email Template</span>
              </a>
            </li>:[]}
            
          </ul>
        </li>
    
        <li className="nav-item">
          <a className="nav-link collapsed" href='/logout' >
            <i className="bi bi-box-arrow-in-right"></i>
            <span>Log Out</span>
          </a>
        </li>

      </ul>
    
    </aside>
  )
}

export default Sidebar