import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import Template from '../components/Template'
import axios from 'axios'
import Select, { components } from 'react-select'
import { CURRENCY, ServerUrl, config, staffCode, styles } from '../components/Includes'
import TextEditor from '../components/editor'
import { ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify'

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'

const EditTemplate =()=> {

  const group  = useSelector((state:any) => state.rootReducer.group);
  let params = useParams()
  const [client, setClient] = useState({
    show_token:true
  });

  const [errors, setErrors] = useState({
   
    subject:'',
    html_body:''
  });

  
  const [content, setContent] = useState([] as any);




  const fetchContent = async()=>{
    LoadingModal()
  
    let url = ServerUrl+'/setup/message_template/show_one/'+params.code
    await  axios.get(url, config).then((result:any)=>{
             if( result.data.data.length!==0){
            
               setContent(result.data.data)
               }
             })
             .catch((error)=>{
                 
          ErrorModal('oops', error.message)
             }).finally(()=>{
                 closeModal()
             })
             } 


       const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	

        if(name==='status'){
          value = value==='Active'?'InActive':'Active'
        }
        setContent({...content, [name]:value});
       setErrors({...errors, [name]:''});
     }

    





     const handleChangeArea =(event:ChangeEvent<HTMLTextAreaElement>)=>{
      let {name, value} = event.target;	

      setContent({...content, [name]:value});
     setErrors({...errors, [name]:''});
   }


    
   
   const handleCopyTemplate =()=>{


    LoadingModal()
 const fd = new FormData();
    Object.entries(content).forEach(([key, value]) => {
            fd.append(key, String(value));
        });
       
      let code = 'e'+Math.random().toString(36).substring(2,9);

        fd.append('code', code);
        fd.append('template_name', content.template_name+'_copy');
        fd.append('subject', content.subject);
        fd.append('html_body', content.html_body);
        fd.append('bcc', content.bcc);
        fd.append('status', content.status);
        fd.append('token', content.token);
        fd.append('sms_sender', content.sms_sender);
        fd.append('sms_body', content.sms_body);
        

   let url = ServerUrl+'/setup/message_template/create';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
     setTimeout(() => {
      
     window.open('/systems/email_template/edit/'+code, '_self')
     }, (2000));
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }) 

}


    const handleSubmit =(event: FormEvent<HTMLButtonElement>, ref:string)=>{
                  event.preventDefault();
              
                  let error = {...errors}; 
                  let formIsValid = true;
              
              let msg ='This field is required';
              
             if(!content.subject){
                  error.subject =msg;
                  formIsValid = false;
              } 

           

            if(!content.html_body){
              error.html_body =msg;
              formIsValid = false;
          } 

        
              if(!formIsValid){
              setErrors(error) 
              }
              
              
              if(formIsValid) {
              

                  LoadingModal()
               const fd = new FormData();
                  Object.entries(content).forEach(([key, value]) => {
                          fd.append(key, String(value));
                      });
              
             
                     
                 let url = ServerUrl+'/setup/message_template/update';
                axios.post(url, fd, config)
                .then(response =>{
                  if(response.data.status === 200){
                   SuccessModal(response.data.message)
                   setTimeout(() => {
                    
                   window.open(ref, '_self')
                   }, (2000));
                            } else{
                              ErrorModal('', response.data.message)
                            }   
                        })
                        .catch((error)=>{
                         ErrorModal('oops', error.message)
                        }) 
                  }
              
              }



          useEffect(()=>{
            fetchContent()
          }, [])

  return (<Template> 

{group.includes('rl13')?
<main id="main" className="main">

<div className="pagetitle">
  <h1>Edit Message Template Details</h1>
  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item"><a href="/systems/email_template">Template</a></li>
      <li className="breadcrumb-item active">Update</li>
    </ol>
<div>
    <button className='btn btn-primary ' onClick={(e)=>handleSubmit(e, '/systems/email_template')}> <i className="bi bi-check2-circle"></i> Save & Return</button>&nbsp;

    <button className='btn btn-success ' onClick={handleCopyTemplate}> <i className='bi bi-copy'></i> Copy Template</button>
&nbsp;

    <a href='/systems/email_template' className='btn btn-warning ' > <i className='bi bi-arrow-forward'></i>Back</a>


    </div>

  </nav>

  
</div>





<section className="section">
  <div className="row">

    <div className="col-lg-12">

      <div className="card">

      <h5 className="card-header"> Email Template info</h5>
        <div className="card-body ">
         

        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Message tokens</label>
                  <div className="col-sm-10">


                    <a href='#' className='text-success pointer' onClick={()=>setClient({...client, show_token:!client.show_token})} > {client.show_token?'Hide':'Show'}</a>

                   {client.show_token? <p className='text-primary'>{content.token}</p>:''}
                   
                   
                    
                    
                    </div>
                </div>


        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Name</label>
                  <div className="col-sm-10">
                   <p>{content.template_name}</p>
                  </div>
                </div>


        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Subject<span className="text-danger">*</span></label>
                  <div className="col-sm-10">
                    <input type="text" name="subject" value={content.subject} onChange={handleChange} className={errors.subject?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  />
                    <i>The subject of the message (Email)</i>
                  </div>
                </div>


               


                <div className=" row mb-20">


<label className="col-sm-2 col-form-label">Body</label>

<div className="col-sm-10">

  <div className="input-group">

<div style={{ width: '100%'}}> 
      

      <textarea name="html_body" onChange={handleChangeArea} className={errors.html_body?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  id="" cols={30} rows={30} value={content.html_body} >

      </textarea>

    </div>
</div> 
<i>The body of your message (Email)</i>

</div>

</div>
<br/>
<div className="row">
<div className="col-md-2"></div>
  
  <div className="col-md-10">
  <h2>Preview</h2>

  <div  dangerouslySetInnerHTML={{__html:content.html_body}}>
</div>

  </div>
  
</div>

                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">BCC<span className="text-danger">*</span></label>
                  <div className="col-sm-10">
                    <input type="text" name="bcc" value={content.bcc} onChange={handleChange} className="form-control form-control-lg"/>
                    <i>The blind carbon copy recipients for this email message</i>
                  </div>
                </div>



<div className="row">
<div className="form-group row">
		
    <label className="col-sm-3 col-form-label">Is Template Active</label>
                  
            <div className="col-sm-4 ">
            <div className="checkbox-fade fade-in-success">
            <label>
          <input type="checkbox" value={content.status} name="status" checked={content.status==='Active'?true:false} onChange={handleChange} />
              <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
            </label>
          </div>
              </div>
          </div>
</div>
        </div>
      </div>

    </div>
  </div>
</section>


			



</main>:[]}

    
    
</Template>
  )
}

export default EditTemplate