import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import Template from '../components/Template'

import axios from 'axios'
import Swal from 'sweetalert2'
import {Chart,ArcElement, registerables } from 'chart.js';
import {Bar, Line, Pie, Doughnut} from 'react-chartjs-2'
import { ErrorModal, LoadingModal, closeModal } from '../components/notify';
import { CURRENCY, ServerUrl, config, staffCode, styles } from '../components/Includes'


const Dashboard =()=> {
  Chart.register(ArcElement, ...registerables)

  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({
    visit:[] as any,
    items:[] as any,
    stat:[] as any
  });



  const fetchContent = async()=>{
    LoadingModal()
  
    let url = ServerUrl+'/admin/history'
    await  axios.get(url, config).then((result:any)=>{
   
             if( result.data.length!==0){
            
               setContent(result.data)
               }
             })
             .catch((error)=>{
                 
          ErrorModal('oops', error.message)
             }).finally(()=>{
                 closeModal()
             })
             } 

  const VisitList = {
    labels: content.visit.map((data:any)=>data.label),
    datasets: [
      {
                 
        label: 'Total Visit',
        backgroundColor: [
          '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
        hoverBackgroundColor: [
        '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
        data: content.visit.map((data:any)=>data.data)
      }
    ]
  }



  const ItemList = {
    labels: content.items.map((data:any)=>data.label),
    datasets: [
      {
                 
        label: 'Total User',
        backgroundColor: [
          '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
        hoverBackgroundColor: [
        '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
        data: content.items.map((data:any)=>data.data)
      }
    ]
  }

  useEffect(()=>{
    fetchContent()
  }, [])

  return (<Template>


<main id="main" className="main">
<div className="pagetitle">
  <h1>Dashboard</h1>
</div>


<section className="section dashboard">
      <div className="row">

        <div className="col-lg-12">
          <div className="row">

            <div className="col-md-4 ">
              <div className="card info-card sales-card">

           

                <div className="card-body">
                  <h5 className="card-title">Total Clients</h5>

                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-people"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{content.stat.total_client}</h6>
                      <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-4 ">
              <div className="card info-card revenue-card">


                <div className="card-body">
                  <h5 className="card-title">Total Staff</h5>

                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-people"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{content.stat.total_admin}</h6>
                      <span className="text-success small pt-1 fw-bold">8%</span> <span className="text-muted small pt-2 ps-1">increase</span>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-4 ">
              <div className="card info-card revenue-card">

             

                <div className="card-body">
                  <h5 className="card-title">Total Visit</h5>

                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-people"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{content.stat.total_visit}</h6>
                      <span className="text-success small pt-1 fw-bold">8%</span> <span className="text-muted small pt-2 ps-1">increase</span>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-xxl-4 col-xl-12">

              <div className="card info-card customers-card">


                <div className="card-body">
                  <h5 className="card-title">Total Exchange</h5>

                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-people"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{content.stat.total_exchange}</h6>
                      <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span>

                    </div>
                  </div>

                </div>
              </div>

            </div>


<div className="row">
<div className="col-md-5 ">
<div className="card ">
<div className="card-header">
    <h5>Items Count</h5>
</div>
<div className="card-block">
<Doughnut data={ItemList} 


/>


</div>
</div>
</div>


            <div className="col-7">
              <div className="card">

                <div className="filter">
                  <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li className="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a className="dropdown-item" href="#">Today</a></li>
                    <li><a className="dropdown-item" href="#">This Month</a></li>
                    <li><a className="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div>

                <div className="card-body">
                  <h5 className="card-title">Profile Visit <span>This Week</span></h5>


                  <Bar
          data={VisitList}
        />  
                </div>

              </div>
            </div>
            </div>
           

          </div>
        </div>

      </div>
    </section>
</main>

  </Template>
  )
}

export default Dashboard