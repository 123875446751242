import React, { ChangeEvent, FormEvent, useCallback, useEffect,  useState } from 'react'
import Template from '../components/Template'
import axios from 'axios'
import Select from 'react-select'
import { ServerUrl, config, styles } from '../components/Includes'
import { useParams } from 'react-router-dom'
import TextEditor from '../components/editor'
import { ErrorModal, CustomLoader, LoadingModal, SuccessModal, closeModal } from '../components/notify'
import Swal from 'sweetalert2'

import { useSelector } from 'react-redux';
import { usePaginated, DOTS } from '../components/usePagination';
import DataTable from 'react-data-table-component';

const EditClient =()=> {

  const group  = useSelector((state:any) => state.rootReducer.group);
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState([] as any);
  const [client, setClient] = useState({} as any);
  let params = useParams()
  const [password, setPassword] = useState({
    password:'',
    is_hidden:true
  });

  const [errors, setErrors] = useState({
    expiry_date:'',
    country:'',
    company:'',
    firstname:'',
    lastname:'',
    username:'',
    email_address:'',
    prefix:'',
    allowExchange:'',
    confirm_password:'',
    password:''
  });


  const handleSelect = (option:any, action:any)=>{
    setClient({...client, [action.name]:option.value});
      setErrors({...errors, [action.name]:''})
       }

       const handleChangePassword =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	

       
        setPassword({...password, [name]:value});
       setErrors({...errors, [name]:''});
     }


       const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	

       
        setClient({...client, [name]:value});
       setErrors({...errors, [name]:''});
     }

     
  

    const handleEditorChange = (content:any, editor:any) => {
      setClient({...client, description:content});
    }


          
    const fetchCards = async()=>{
      let url = ServerUrl+'/card/display/client/'+params.code
        await  axios.get(url,  config).then((result:any)=>{
          
          if(result.data.data&&result.data.data.length!==0){
            setCard(result.data.data)
          }
          })
        }

    const handleSubmit =(event: FormEvent<HTMLButtonElement>, ref:string)=>{
                  event.preventDefault();
              
                  let error = {...errors}; 
                  let formIsValid = true;
              
              let msg ='This field is required';
              
              
              if(!client.firstname){
                error.firstname =msg;
                formIsValid = false;
            } 


         
         /*  if(client.prefix.length===0){
            error.prefix=msg;
            formIsValid = false;
        }

          if(!client.company){
            error.company =msg;
            formIsValid = false;
        }  */

        if(!client.username){
          error.username =msg;
          formIsValid = false;
      }


         
           
        if(!client.expiry_date){
          error.expiry_date =msg;
          formIsValid = false;
      }

        
        if(!client.country){
          error.country =msg;
          formIsValid = false;
      } 


              if(!formIsValid){
              setErrors(error) 
              }
              
              
              if(formIsValid) {
              
                  LoadingModal()
               const fd = new FormData();
                  Object.entries(client).forEach(([key, value]) => {
                          fd.append(key, String(value));
                      });
              
                      
                 let url = ServerUrl+'/client/update/'+params.code;
                axios.post(url, fd, config)
                .then(response =>{
                  if(response.data.status === 200){
                   SuccessModal(response.data.message)
                   setTimeout(() => {
                    
                   window.open(ref, '_self')
                   }, (2000));
                            } else{
                              ErrorModal('', response.data.message)
                            }   
                        })
                        .catch((error)=>{
                         ErrorModal('oops', error.message)
                        }) 
                  }
              
              }


              const fetchContent = async()=>{
               LoadingModal()
         
                     let url = ServerUrl+'/clients/display/user/'+params.code
                   await  axios.get(url,  config).then((result:any)=>{
                    
                    if(result.data.data&&result.data.data.length!==0){


                      try{

                    
                       setClient(result.data.data)
                    
                    }catch(e){

                    }
                       }else{
                         window.open('/clients', '_self')
                       }
                     })
                     .catch((error)=>{
                      ErrorModal('oops', error.message)
                     }).finally(()=>{
                      closeModal()
                  })
                     } 

                  



                  const DeleteModal =(row:any)=>{

                   
                    let url = ServerUrl+'/client/delete/'+params.code;
                   

                    Swal.fire({
                      title: 'Are you really sure?',
                      text: "This is very dangerous, you shouldn't do it!",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      showLoaderOnConfirm: true,
                    preConfirm: async() => {
                      return await axios.get(url, config).then(response=>{
                       if(response.data.status!==200){
                            throw new Error(response.statusText)
                          }
                          return response.data
                        })
                        .catch(error => {
                          Swal.showValidationMessage(
                            `Request failed: ${error}`
                          )
                        })
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                      confirmButtonText: 'Yes, I am!'
                    }).then((result:any) => {
                      if (result.isConfirmed && result.value.status===200) {
                       
                         Swal.fire(
                                    'Deleted!',
                                    'Record has been deleted.',
                                    'success'
                                  ) 
                      }
                    })
                  }


                  function updatePassword(){  
                    if(password.password===''){
                      ErrorModal('oops', 'Please enter  new password')
                    }else{
                     LoadingModal()   
                      
                      const fd = new FormData();
                      fd.append('password', password.password)
                       let url = ServerUrl+'/admin/change_client_password/'+params.code;
                      axios.post(url, fd, config)
                      .then(response =>{
                       if(response.data.status ===200){	
                        SuccessModal(response.data.message)
                          } else{
            
                        ErrorModal('oops', response.data)
                          }   
                      })
                      .catch((error)=>{
                        ErrorModal('oops', error.message)
                      })
                  }
                }






         const TableData =()=>{

          const PaginationWrapper =(props:any)=>{
          
              const handleRowsPerPage = useCallback(
                  (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                  [props.currentPage, props.onChangeRowsPerPage],
              );
              const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
              [props.currentPage, props.onChangeRowsPerPage],
              )
             
              function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                  return Math.ceil(rowCount / rowsPerPage);
                }
              const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
              const lastIndex = props.currentPage * props.rowsPerPage;
              const firstIndex = lastIndex - props.rowsPerPage + 1;
          
          
                  const range =
                  props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
          
                  `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
          
              
             
              const Pagination = () => {
                 
                  const pageSize = props.rowsPerPage;
                  const totalCount = card.length
                  const siblingCount = 1
                  const currentPage = props.currentPage
                  
                  const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                  
                  if (currentPage === 0 ) {
                    return null;
                  }
                  
                  let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                  return (
                  <nav className="" aria-label="Page navigation sample ">
                    <ul  className="pagination pagination-sm"  >
                      <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                       <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                      </li>
                      {paginationRange&&paginationRange.map((pageNumber, index) => {
                        if (pageNumber === DOTS) {
                          return <li className="pagination-item dots" key={index}>
                           <a className="page-link pointer"  >&#8230;</a>
                          </li>
                        }
                        return (
                          <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                            <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                            
                          </li>
                        );
                      })}
                  
                  <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                  <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                      </li>
                  
                    </ul> </nav>
                  );
                  
                      }
          
                    
          
                    
          
           return <div className="row mt-3">
                              
          <div className="col-md-5 ">
          
          
             Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40-h-30 ' onChange={handleRowsPerPage}>
                  <option value="10"> 10</option>
                  <option value="20"> 20</option>
                  <option value="30"> 30</option>
                  <option value="40"> 40</option>
                  <option value="50"> 50</option>
                  <option value={card.length}> All</option>
              </select> &nbsp;&nbsp;
               {range}  </div>
          
            
          <div className="col-md-7  justify-content-end d-flex">
             <Pagination  />
              </div>
              
          </div>}
          
          
          
                      const columns = [
                        {
                          name: 'Serial No',
                          cell: (row:any, index:number) => row.serial_no, 
                          sortable: true,
                          reorder:true,
        
                      },
                          {
                            name: 'Client',
                            cell: (row:any) => row.fullname, 
                            sortable: true,
                            reorder:true,
                            wrap:true,
          
                        },
                    {
                      name: 'Payment Status',
                      cell: (row:any) =>  <span className={`badge rounded-pill ${row.payment_status==='Pending'?'bg-danger':row.payment_status==='Paid'?'bg-success':'bg-primary'}`} >{row.payment_status}</span>, 
                      sortable: true,
                      reorder:true,
                      wrap:true,
    
                  },
                    
                  {
                    name: 'Card Status',
                    cell: (row:any) => <span className={`badge rounded-pill ${row.card_status==='Pending'?'bg-danger':row.card_status==='Processing'?'bg-primary':'bg-success'}`} >{row.card_status}</span>,
                    sortable: true,
                    reorder:true,
                    wrap:true,
  
                },
                {
                  name: 'Date Apply',
                  cell: (row:any) => new Date(row.created_at).toISOString().slice(0,19), 
                  sortable: true,
                  reorder:true,
                  wrap:true,

              },
                {
                  name: 'Action',
                 cell: (row:any, index:any, column:any, id:any) => <div className='col'>  
                    
                      <a href={'/client/card/view/'+row.code} className="btn btn-info btn-sm "   ><i className='bi bi-eye'></i> View </a>&nbsp;


                 </div>,
                     left:true,
                           
 
             },
                      ];
                      
                  
                  
                  
                      return <>
                      
          
                      <DataTable
                      columns={columns}
                      data={card}
                      highlightOnHover
                      pagination
                      paginationComponent={PaginationWrapper}
                      selectableRowsHighlight
                      striped
                      responsive
                      fixedHeader
                      progressPending={loading}
                      progressComponent={<CustomLoader />}
                  />
                  </>
                  }




useEffect(()=>{
  fetchContent()
  fetchCards()
}, [])

  return (<Template> 

{group.includes('rl2')?<main id="main" className="main">

<div className="pagetitle">
  <h1>Update Client</h1>
  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item"><a href="/clients">Client</a></li>
      <li className="breadcrumb-item active">Update </li>
    </ol>
<div>
    <button className='btn btn-success ' onClick={(e)=>handleSubmit(e, '/clients')}> <i className="bi bi-check2-circle"></i> Save Changes</button>&nbsp;

    <button className='btn btn-warning ' onClick={(e)=>handleSubmit(e, '/client/edit/'+params.code)}> <i className='bi bi-pencil'></i>Save & Edit</button>&nbsp;

    <button className='btn btn-danger ' onClick={DeleteModal}> <i className="bi bi-trash"></i> Delete</button>&nbsp;


    <a href='/clients' className='btn btn-info ' > <i className="bi bi-arrow-left"></i> Back</a>&nbsp;

    </div>

  </nav>

  
</div>





<section className="section">
  <div className="row">

    <div className="col-lg-12">

      <div className="card">

      <h5 className="card-header">Basic Details</h5>
        <div className="card-body ">
         

        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Prefix </label>
                  <div className="col-sm-4">


                  <Select  options={[ 
{value:'', label:''},
{value:'Apostle', label:'Apostle'},
{value:'Mr.', label:'Mr.'},
{value:'Mrs.', label:'Mrs.'}, 
{value:'Ms.', label:'Ms.'}, 
 {value:'Dr', label:'Dr.'}, 

 {value:'General', label:'General'}, 
{value:'Esquire', label:'Esquire'},
{value:'Honorable', label:'Hon.'}, 
{value:'Mallam', label:'Mallam'},
{value:'Pst.', label:'Pst.'},
{value:'Junior', label:'Jr.'}, 
{value:'Messrs.', label:'Messrs.'},
{value:'Mmes.', label:'Mmes.'}, 
{value:'Msgr.', label:'Msgr.'},
{value:'Prof.', label:'Prof.'}, 
{value:'Rev.', label:'Rev.'},
{value:'Rt. Hon.', label:'Rt. Hon.'}, 
{value:'Sr.', label:'Sr.'}, 
{value:'St.', label:'St.'}
           ]}   
styles={styles}
onChange={handleSelect}  name="prefix" value={{value:client.prefix, label:client.prefix}} 

className={errors.prefix?"form-control form-control-danger form-control-lg":""}
/>

                    
                  </div>


                  <label className="col-sm-2 col-form-label">Allow  Exchange </label>
                  <div className="col-sm-4">
                   
                  <Select  options={[ 

              {value:'Yes', label:'Yes'},
              {value:'No', label:'No'}, 
                        ]}   
                        styles={styles}
onChange={handleSelect}  name="allowExchange" value={{value:client.allowExchange, label:client.allowExchange}} 

/>

                   
                  </div>


                </div>



        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Company</label>
                  <div className="col-sm-10">
                    <input type="text" name="company" value={client.company} onChange={handleChange} className={errors.company?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  />
                    <i>Company or Office or Workstation</i>
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">First Name<span className="text-danger">*</span></label>
                  <div className="col-sm-4">
                    <input type="text"  className={errors.firstname?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="firstname" value={client.firstname} onChange={handleChange} />
                  </div>


                  <label className="col-sm-2 col-form-label">Last Name<span className="text-danger">*</span></label>
                  <div className="col-sm-4">
                    
                  <input type="text"  className={errors.lastname?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="lastname" value={client.lastname} onChange={handleChange} />
                  </div>
                </div>


                <hr/>
                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Address</label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control form-control-lg"   name="address" value={client.address} onChange={handleChange} />
                    <i>Includes Landmark and Neighborhood</i>
                  </div>
                </div>

                
                <div className="row mb-3 mt-3">
                <div className="col-sm-2"></div>

                <div className="col-sm-3">
                  <label className=" col-form-label">Country <span className="text-danger">*</span></label>
                  
                    <input type="text"  className={errors.country?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="country" value={client.country} onChange={handleChange} />
                  </div>


                  <div className="col-sm-3">
                  <label className=" col-form-label">State</label>
                 
                    <input type="text" className="form-control form-control-lg" name="region" value={client.region} onChange={handleChange} />
                  </div>

                  <div className="col-sm-3">
                  <label className=" col-form-label">City</label>
                
                    <input type="text" className="form-control form-control-lg" name="city" value={client.city} onChange={handleChange} />
                  </div>
                </div>




                <hr/>

                <div className="row mb-3 mt-3">
                <div className="col-sm-2"></div>

                <div className="col-sm-4">
                  <label className=" col-form-label">Email Address: <span className="text-danger">*</span></label>
                  
                    
                  <input type="text"  disabled className={errors.email_address?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="email_address" value={client.email_address} onChange={handleChange} />
                  </div>


                  <div className="col-sm-4">
                  <label className=" col-form-label">Username: <span className="text-danger">*</span></label>
                  
                    
                  <input type="text" role='presentation'  className={errors.username?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}name="username" value={client.username} onChange={handleChange} />
                  </div>

                </div>



        </div>
      </div>

    </div>
  </div>
</section>


<div className="accordion mb-3" id="accordionAdvance">
                <div className="accordion-item">
                  <h2 className="accordion-header " id="headingAdvance">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdvance" aria-expanded="true" aria-controls="collapseAdvance">
                    Advance Details
                    </button>
                  </h2>
                  <div id="collapseAdvance" className="accordion-collapse collapse show" aria-labelledby="headingAdvance" data-bs-parent="#accordionAdvance" >
                    <div className="accordion-body">



                    <div className=" row mb-20">


      <label className="col-sm-2 col-form-label">About Client</label>

<div className="col-sm-10">

        <div className="input-group">

 <div style={{ width: '100%'}}> 
            
  <TextEditor
  description={client.about}
  handleEditorChange ={handleEditorChange}
      height={300}        
    /> 

          </div>
</div> 
</div>

</div>


<div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Expiry Date <span className="text-danger">*</span> </label>
                  <div className="col-sm-4">

                  <input type="date"  className={errors.expiry_date?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="expiry_date" value={client.expiry_date} onChange={handleChange} />

                    
                  </div>


                  <label className="col-sm-2 col-form-label">Position </label>
                  <div className="col-sm-4">

<input type="text" className="form-control form-control-lg" name="position" value={client.position} onChange={handleChange} />

  
</div>


                </div>



                    </div>
                  </div>
                </div>
   </div>

			







   <div className="accordion mb-3 " id="accordionPassword">
                <div className="accordion-item ">
                  <h2 className="accordion-header " id="headingPassword">
                    <button className="accordion-button bg-success text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePassword" aria-expanded="true" aria-controls="collapsePassword">
                    Update Password
                    </button>
                  </h2>
                  <div id="collapsePassword" className="accordion-collapse collapse show" aria-labelledby="headingPassword" data-bs-parent="#accordionPassword" >
                    <div className="accordion-body">

<div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">New Password <span className="text-danger">*</span> </label>
                  <div className="col-sm-6">


                  <div className="input-group">
                      <input type={password.is_hidden?'password':'text'} id="password" name="password" required autoComplete='off' role={'presentation'} value={password.password} onChange={handleChangePassword} className={errors.password  ? 'form-control formerror ' : 'form-control '} /> 
                     <span className='btn btn-primary' onClick={()=>setPassword({...password, is_hidden:!password.is_hidden})} > <i className={password.is_hidden?'bi bi-eye text-white':'bi bi-eye-slash text-white'}></i></span>

							</div>
                  </div>

                  <div className="col-md-3">
              <button type='button' className='btn btn-warning ' onClick={updatePassword}> Update Password </button>
              </div>
                </div>



                    </div>
                  </div>
                </div>
   </div>










   <div className="accordion mb-3 " id="accordionCard">
                <div className="accordion-item ">
                  <h2 className="accordion-header " id="headingCard">
                    <button className="accordion-button bg-info text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCard" aria-expanded="true" aria-controls="collapseCard">
                   Card History
                    </button>
                  </h2>
                  <div id="collapseCard" className="accordion-collapse collapse show" aria-labelledby="headingCard" data-bs-parent="#accordionCard" >
                    <div className="accordion-body">




                    <TableData />










                    </div>
                  </div>
                </div>
   </div>

</main>:[]}

    
    

    
    
</Template>
  )
}

export default EditClient