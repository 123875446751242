import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AddClient from './products/AddClient';
import Clients from './products/Clients';
import Dashboard from './pages/Dashboard';
import AddUser from './Users/AddUser';
import ChangePassword from './Users/Change_Password';
import EditClient from './products/EditClient';
import EditUser from './Users/EditUser';
import Users from './Users/Users';
import Items from './pages/Items';
import Setup from './systems/Setup';
import BulkMessage from './pages/BulkMessage';
import EmailTemplate from './systems/EmailTemplate';
import EditTemplate from './systems/EditTemplate';
import Location from './systems/Location';
import CardRequest from './payments/card_request';
import CardDetails from './payments/card_details';
import Referal from './Users/Referer';
import Group from './Users/Group';

const ProtectedView =()=>{

    
        return ( <>
       
       <Routes>

<Route  path="/client/add" element={<AddClient /> } /> 
<Route  path="/client/edit/:code" element={<EditClient /> } /> 
<Route  path="/clients" element={<Clients /> } /> 



<Route  path="/dashboard" element={<Dashboard /> } /> 
<Route  path="/items" element={<Items /> } /> 
<Route  path="/users" element={<Users /> } /> 
<Route  path="/user/add" element={<AddUser /> } /> 
<Route  path="/user/edit/:code" element={<EditUser /> } />
<Route  path="/user/password" element={<ChangePassword /> } />  
<Route  path="/user/group" element={<Group /> } /> 

<Route  path="/setup" element={<Setup /> } /> 
<Route  path="/send_message" element={<BulkMessage /> } /> 

<Route  path="/systems/email_template" element={<EmailTemplate /> } /> 
<Route  path="/systems/location" element={<Location /> } /> 

<Route  path="/client/card_request" element={<CardRequest /> } /> 
<Route  path="/client/card/view/:code" element={<CardDetails /> } /> 
<Route  path="/user/referer" element={<Referal /> } /> 
<Route  path="/systems/email_template/edit/:code" element={<EditTemplate /> } /> 


  {/*  <Route  path="/project/dashboard" element={<ProjectDashboard />} />


   

        <Route  path="*" element={<Error404 />} /> 
 */}
        </Routes>
      </>
    );
  
}
export default ProtectedView;
