import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import Template from '../components/Template'
import axios from 'axios'
import Select, { components } from 'react-select'
import { CURRENCY, ServerUrl, config, staffCode, styles } from '../components/Includes'
import TextEditor from '../components/editor'
import { ErrorModal, LoadingModal, SuccessModal } from '../components/notify'

import { useSelector } from 'react-redux';
const AddProduct =()=> {

  const group  = useSelector((state:any) => state.rootReducer.group);
	const [clientCode, setClientCode] = useState('eki'+Math.random().toString(36).substring(2,9))
  let expire = new Date(new Date().setFullYear(new Date().getFullYear() + 2)).toISOString().slice(0,10);

let Initial = {

  prefix:'',	
  company:'',	
  role:'',	
  job_title:'',	
  country:'',	
  region:'',	
  city:'',	
  street:'Nigeria',	
  address:'Nigeria',	
  more_info:'',	
  about:'',	
  photo:'',	
  firstname:'',	

  middlename:'Name',
  lastname:'',	
  username:'',	
  position:'',	
  password:'',
  confirm_password:'',	
  email_address:'',	
  expiry_date:'',	
  allowExchange:'',	
  staff_create:staffCode,

referer:'none' ,
referer_no:'none'
}


  const [client, setClient] = useState(Initial);

  const [errors, setErrors] = useState({
   
    expiry_date:'',
    country:'',
    company:'',
    firstname:'',
    lastname:'',
    username:'',
    email_address:'',
    prefix:'',
    allowExchange:'',
    confirm_password:'',
    password:''
  });

  


  const handleSelect = (option:any, action:any)=>{
    setClient({...client, [action.name]:option.value});
      setErrors({...errors, [action.name]:''})
       }

       const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	

        setClient({...client, [name]:value});
       setErrors({...errors, [name]:''});
     }

    





    const handleEditorChange = (content:any, editor:any) => {
      setClient({...client, about:content});
    }


          

    const handleSubmit =(event: FormEvent<HTMLButtonElement>, ref:string)=>{
                  event.preventDefault();
              
                  let error = {...errors}; 
                  let formIsValid = true;
              
              let msg ='This field is required';
              
              if(!client.firstname){
                  error.firstname =msg;
                  formIsValid = false;
              } 

              let email = client.email_address.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
            if(!email){
              error.email_address =msg;
              formIsValid = false;
            } 

           
          /*   if(client.prefix.length===0){
              error.prefix=msg;
              formIsValid = false;
          } */

          /*   if(!client.company){
              error.company =msg;
              formIsValid = false;
          }  */

          if(!client.username){
            error.username =msg;
            formIsValid = false;
        }


              if(!client.password){
                error.password =msg;
                formIsValid = false;
            }
              
            if(!client.confirm_password){
              error.confirm_password =msg;
              formIsValid = false;
          }
             
          if(!client.expiry_date){
            error.expiry_date =msg;
            formIsValid = false;
        }

          
          if(!client.country){
            error.country =msg;
            formIsValid = false;
        } 

      if(client.password.length <8){
          error.password ='Please enter minimum of 8 characters';
          formIsValid = false;
        } 


              if(!formIsValid){
              setErrors(error) 
              }
              
              
              if(formIsValid) {
              

                  LoadingModal()
               const fd = new FormData();
                  Object.entries(client).forEach(([key, value]) => {
                          fd.append(key, String(value));
                      });
              
             
                      fd.append('code', clientCode);
                     
                 let url = ServerUrl+'/client/add';
                axios.post(url, fd, config)
                .then(response =>{
                  if(response.data.status === 200){
                   SuccessModal(response.data.message)
                   setTimeout(() => {
                    
                   window.open(ref, '_self')
                   }, (2000));
                            } else{
                              ErrorModal('', response.data.message)
                            }   
                        })
                        .catch((error)=>{
                         ErrorModal('oops', error.message)
                        }) 
                  }
              
              }


              const ValidateExistence=()=>{
                if(client.email_address!==''){
                      
                    let url = ServerUrl+'/client/existence/'+client.email_address;
                    axios.get(url, config).then(response=>{
                        if(response.data.status===200){
                            setErrors({...errors, email_address:client.email_address + ' already exist'})
                            setClient({...client, email_address:''});
                            return response.data;
                        }
                  })
                }
          }


  return (<Template> 

{group.includes('rl2')?<main id="main" className="main">

<div className="pagetitle">
  <h1>Add New Client</h1>
  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item"><a href="/clients">Client</a></li>
      <li className="breadcrumb-item active">Add New</li>
    </ol>
<div>
    <button className='btn btn-success ' onClick={(e)=>handleSubmit(e, '/clients')}> <i className="bi bi-check2-circle"></i> Save Client</button>&nbsp;

    <button className='btn btn-warning ' onClick={(e)=>handleSubmit(e, '/client/edit/'+clientCode)}> <i className='bi bi-pencil'></i>Save & Edit</button>
    </div>

  </nav>

  
</div>





<section className="section">
  <div className="row">

    <div className="col-lg-12">

      <div className="card">

      <h5 className="card-header">Basic Details</h5>
        <div className="card-body ">
         

        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Prefix </label>
                  <div className="col-sm-4">


                  <Select  options={[ 
{value:'', label:''},
{value:'Apostle', label:'Apostle'},
{value:'Mr.', label:'Mr.'},
{value:'Mrs.', label:'Mrs.'}, 
{value:'Ms.', label:'Ms.'}, 
 {value:'Dr', label:'Dr.'}, 

 {value:'General', label:'General'}, 
{value:'Esquire', label:'Esquire'},
{value:'Honorable', label:'Hon.'}, 
{value:'Mallam', label:'Mallam'},
{value:'Pst.', label:'Pst.'},
{value:'Junior', label:'Jr.'}, 
{value:'Messrs.', label:'Messrs.'},
{value:'Mmes.', label:'Mmes.'}, 
{value:'Msgr.', label:'Msgr.'},
{value:'Prof.', label:'Prof.'}, 
{value:'Rev.', label:'Rev.'},
{value:'Rt. Hon.', label:'Rt. Hon.'}, 
{value:'Sr.', label:'Sr.'}, 
{value:'St.', label:'St.'}
           ]}   
styles={styles}
onChange={handleSelect}  name="prefix" value={{value:client.prefix, label:client.prefix}} 

className={errors.prefix?"form-control form-control-danger form-control-lg":""}
/>

                    
                  </div>


                  <label className="col-sm-2 col-form-label">Allow  Exchange </label>
                  <div className="col-sm-4">
                   
                  <Select  options={[ 

              {value:'Yes', label:'Yes'},
              {value:'No', label:'No'}, 
                        ]}   
                        styles={styles}
onChange={handleSelect}  name="allowExchange" value={{value:client.allowExchange, label:client.allowExchange}} 

/>

                   
                  </div>


                </div>



        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Company </label>
                  <div className="col-sm-10">
                    <input type="text" name="company" value={client.company} onChange={handleChange} className={errors.company?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  />
                    <i>Company or Office or Workstation</i>
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">First Name<span className="text-danger">*</span></label>
                  <div className="col-sm-4">
                    <input type="text"  className={errors.firstname?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="firstname" value={client.firstname} onChange={handleChange} />
                  </div>


                  <label className="col-sm-2 col-form-label">Last Name<span className="text-danger">*</span></label>
                  <div className="col-sm-4">
                    
                  <input type="text"  className={errors.lastname?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="lastname" value={client.lastname} onChange={handleChange} />
                  </div>
                </div>


                <hr/>
                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Address</label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control form-control-lg"   name="address" value={client.address} onChange={handleChange} />
                    <i>Includes Landmark and Neighborhood</i>
                  </div>
                </div>

                
                <div className="row mb-3 mt-3">
                <div className="col-sm-2"></div>

                <div className="col-sm-3">
                  <label className=" col-form-label">Country</label>
                  
                    <input type="text"  className={errors.country?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="country" value={client.country} onChange={handleChange} />
                  </div>


                  <div className="col-sm-3">
                  <label className=" col-form-label">State</label>
                 
                    <input type="text" className="form-control form-control-lg" name="region" value={client.region} onChange={handleChange} />
                  </div>

                  <div className="col-sm-3">
                  <label className=" col-form-label">City</label>
                
                    <input type="text" className="form-control form-control-lg" name="city" value={client.city} onChange={handleChange} />
                  </div>
                </div>




                <hr/>

                <div className="row mb-3 mt-3">
                <div className="col-sm-2"></div>

                <div className="col-sm-4">
                  <label className=" col-form-label">Email Address: <span className="text-danger">*</span></label>
                  
                    
                  <input type="text"  onBlur={ValidateExistence} className={errors.email_address?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="email_address" value={client.email_address} onChange={handleChange} />

                  <span className="text-danger">{errors.email_address}</span>
                  </div>


                  <div className="col-sm-4">
                  <label className=" col-form-label">Username: <span className="text-danger">*</span></label>
                  
                    
                  <input type="text" role='presentation'  className={errors.username?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}name="username" value={client.username} onChange={handleChange} />
                  </div>

                </div>


                <div className="row mb-3 mt-3">
                <div className="col-sm-2"></div>

                <div className="col-sm-4">
                  <label className=" col-form-label">Password: <span className="text-danger">*</span></label>
                  
                    
                  <input type="password" role='presentation' className={errors.password?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="password" value={client.password} onChange={handleChange} />
                  </div>


                  <div className="col-sm-4">
                  <label className=" col-form-label">Confirm Password: <span className="text-danger">*</span></label>
                  
                    
                  <input type="password" role='presentation' className={errors.confirm_password?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}name="confirm_password" value={client.confirm_password} onChange={handleChange} />
                  </div>

                </div>


        </div>
      </div>

    </div>
  </div>
</section>


<div className="accordion mb-3" id="accordionAdvance">
                <div className="accordion-item">
                  <h2 className="accordion-header " id="headingAdvance">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdvance" aria-expanded="true" aria-controls="collapseAdvance">
                    Advance Details
                    </button>
                  </h2>
                  <div id="collapseAdvance" className="accordion-collapse collapse show" aria-labelledby="headingAdvance" data-bs-parent="#accordionAdvance" >
                    <div className="accordion-body">



                    <div className=" row mb-20">


      <label className="col-sm-2 col-form-label">About Client</label>

<div className="col-sm-10">

        <div className="input-group">

 <div style={{ width: '100%'}}> 
            
  <TextEditor
  description={client.about}
  handleEditorChange ={handleEditorChange}
      height={300}        
    /> 

          </div>
</div> 
</div>

</div>


<div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Expiry Date <span className="text-danger">*</span> </label>
                  <div className="col-sm-4">

                  <input type="date"  className={errors.expiry_date?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="expiry_date" value={client.expiry_date} onChange={handleChange} />

                    
                  </div>


                  <label className="col-sm-2 col-form-label">Position </label>
                  <div className="col-sm-4">

<input type="text" className="form-control form-control-lg" name="position" value={client.position} onChange={handleChange} />

  
</div>


                </div>



                    </div>
                  </div>
                </div>
   </div>

			



</main>:[]}

    
    
</Template>
  )
}

export default AddProduct