import React, {useState, useRef, useEffect, ChangeEvent, FormEvent} from 'react'
import Template from '../components/Template'
import { ImagesUrl, ServerUrl, styles, staffCode, config } from '../components/Includes'

import { useSelector } from 'react-redux';
import Select, { components } from 'react-select'
import axios from 'axios'
import { ErrorModal, LoadingModal, SuccessModal } from '../components/notify'

const AddUser =()=> {
    let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const groups  = useSelector((state:any) => state.rootReducer.group);
	const [group, setGroup] = useState([])
	
	const [content, setContent] = useState({
		email:'',
		password:'',
		fullname:'',
		gender:'',
		telephone:'',
		isLogo:false, 
		group:[] as any,
		isActive:'true',
		staffCreate: staffCode,
        filePath:'', 
        passport:'', 
        imagesFile:''
	})
	const [errors, setErrors] = useState({
        email:'',
        password:'',
        fullname:'',
        imagesFile:''
    });
	
	const setLogo =()=>{
	 
        var doc = document.getElementById('photo')
        doc?.click()
        
		setContent({...content, isLogo:false, passport:'',  imagesFile:'', filePath:'', })
	}


	const fetchContent = async()=>{
	  
		let url = ServerUrl+'/user/group/display/all'
		await  axios.get(url, config).then((result:any)=>{
	   
				 if(Array.isArray(result.data.data)){
				
				   setGroup(result.data.data)
				   }
				 })
				 .catch((error)=>{
					 
			  ErrorModal('oops', error.message)
				 })
				 } 


    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
		let {name, value} = event.target;
	 	if(name==='isActive'){
			value = value==='false'?'true':'false'
		} 

		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}


	  const handleSelect = (option:any, action:any)=>{
		setContent({...content, [action.name]: option});
		setErrors({...errors, [action.name]:''})
		 }
	
	const ValidateExistence=()=>{
        if(content.email!==''){
              
            const fd = new FormData();
            fd.append("data", content.email)
            let url = ServerUrl+'/admin/existence/email';
            axios.post(url, fd, config).then(response=>{
                if(response.data.status===200){
                    setErrors({...errors, email:content.email + ' already exist'})
                    setContent({...content, email:''});
                    return response.data;
                }
          })
        }
	}
	
	


	const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
		event.preventDefault();
	
		let error = {...errors}; 
		let formIsValid = true;
	
	let msg ='This field is required';
	let email = content.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
	if(!email){
		error.email =msg;
		formIsValid = false;
	} 

	if(!content.password){
		error.password =msg;
		formIsValid = false;
	} 


	if(!content.fullname){
		error.fullname =msg;
		formIsValid = false;
	} 

	if(content.password.length <8){
		 error.password ='Please enter minimum of 8 characters';
		 formIsValid = false;
	 } 


	

	if(!formIsValid){
		setErrors(error) 
		}
		
		
		if(formIsValid) {
		
			LoadingModal()
			const fd = new FormData();
			   Object.entries(content).forEach(([key, value]) => {
					   fd.append(key, String(value));
				   });
				  

				   if(content.imagesFile){
					fd.append('imageFile', content.imagesFile)
				   }
				   fd.append('group_code', content.group.value)

				   let url = ServerUrl+'/admin/add';
				   axios.post(url, fd, config)
				   .then(response =>{
					 if(response.data.status === 200){
					  SuccessModal(response.data.message)
					 setContent({
						email:'',
					password:'',
					fullname:'',
					gender:'',
					telephone:'',
					isLogo:false, 
					group:[] as any,
					isActive:'true',
					staffCreate: staffCode,
					filePath:'', 
					passport:'', 
					imagesFile:''
					 })


							   } else{
								 ErrorModal('', response.data.message)
							   }   
						   })
						   .catch((error)=>{
							ErrorModal('oops', error.message)
						   }) 
		}

}
		
        const  handleFile = function(fieldName:any){
            return function(newValue:any){                 
           readURL(newValue);
           }
          } 
          const readURL = (input:any)=>{
 
            let displayMessage = '';
            
            const PreviewImage = input.target.name;
            const inputElement = document.getElementById('logo') as HTMLInputElement
            const fileName = inputElement.value
            
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            
            if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
                //TO DO    
                
                 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
            if (FileSize > 1) {
                displayMessage = ' File uploaded is larger than maximum size Allow (500 kb).';
                inputElement.value = '';
             
                setContent({...content, filePath:'', imagesFile:''})
            
            } else {
                if (input.target.files && input.target.files[0]) { //Check if input has files.
                    var reader = new FileReader();//Initialize FileReader.
                    reader.onload = function (e:any) {

						  

                    //Check if input has files.
                    let Id = Math.random().toString(36).substring(2, 9)
                     let fileUrl = Id+'.png'
            
                     setContent({...content,
                        filePath:fileUrl, 
                        passport:e.target.result, 
						isLogo:true,
                        imagesFile:input.target.files[0]})
                      setErrors({...errors, imagesFile:''}); 
            
                    }
                    reader.readAsDataURL(input.target.files[0]);
                    
                }
            }
            }else{
            displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 500 kb'
            inputElement.value = '';
            setContent({...content, filePath:'', imagesFile:''})
            }   
            
            if(displayMessage.length > 0){
           ErrorModal("", displayMessage)
            }
            }
		

useEffect(()=>{
	fetchContent()
}, [])

  return (<Template>

{groups.includes('rl12')?
<main id="main" className="main">
<div className="pagetitle">
  <h1>Add User</h1>
  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/users">Users</a></li>
      <li className="breadcrumb-item active">Add New</li>
    </ol>
<div>
    <button className='btn btn-success ' onClick={handleSubmit}> <i className="bi bi-check2-circle"></i> Add New User</button>&nbsp;

    </div>

  </nav>
</div>




<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">User info</h5>

<div className="card-body"> 

<section className="card-block">
<div className="form-group row mt-3 mb-3">

<label className="col-sm-3 col-form-label">Email Address</label>
					
									
						<div className="col-sm-9 ">

							<div className="input-group">
						<input type="text" role='presentation' autoComplete='off' onChange={handleChange}  onBlur={ValidateExistence} name="email" value={content.email} className={errors.email  ? 'form-control form-control-danger ' : 'form-control '} />
							<span className="text-danger"> *</span>
							
							</div>
							<span className="error">{errors.email}</span>
							</div>
					</div>




					<div className="form-group row mb-3">
				
<label className="col-sm-3 col-form-label">Password</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="password" autoComplete="off" onChange={handleChange}   name="password" value={content.password} className={errors.password  ? 'form-control form-control-danger ' : 'form-control '}/>
							<span className="text-danger"> *</span>
							
							</div>
							<span className="error">{errors.password}</span>
							</div>
					</div>
					<div className="form-group row mb-3">
			
<label className="col-sm-3 col-form-label">Fullname</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="fullname" value={content.fullname} className={errors.fullname  ? 'form-control form-control-danger ' : 'form-control '}/>
						<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.fullname}</span>
							</div>
					</div>
					<div className="form-group row mb-3">
				
<label className="col-sm-3 col-form-label">Telephone</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="telephone" value={content.telephone} className='form-control'/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
				
<label className="col-sm-3 col-form-label">Gender</label>
									
						<div className="col-sm-9 ">
						
			<div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Male' checked={content.gender==='Male'?true:false} />
						<i className="helper"></i> Male
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Female' checked={content.gender==='Female'?true:false} />
						<i className="helper"></i> Female
					</label>
				</div>
				</div>

							</div>
					</div>


					<div className="form-group row">
		
<label className="col-sm-3 col-form-label">Is User Active</label>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isActive} name="isActive" checked={content.isActive==='true'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


				<div className="form-group row mb-3">
			
<label className="col-sm-3 col-form-label">User Group</label>
									
						<div className="col-sm-9 ">
				
			<Select  options={
                    group&&group.map((list:any, id:number)=>{
                         return {key:id, value:list.code, label:list.group_name}
                             })}
                       
                                onChange={handleSelect}  name="group"
                        value={content.group} 
                 />
</div>
	</div>
	<div className="form-group row mb-3">
				
<label className="col-sm-3 col-form-label">Profile Image</label>
									
						<div className="col-sm-9 ">
						

						<div className="form-group">
						<img className="img-100 p-image " id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} src={content.passport}   />
				<label className="btn btn-primary btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{content.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round" > Remove File</button>:''}	
										</div>
							</div>
					</div>

	</section>
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>

</main>:[]}

    </Template>
  )
}

export default AddUser