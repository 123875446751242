import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import Template from '../components/Template'

import axios from 'axios'
import { ServerUrl, config } from '../components/Includes'
import { usePaginated, DOTS } from '../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { CustomLoader, ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify';

import { useSelector } from 'react-redux';
const Group =()=> {


  const group  = useSelector((state:any) => state.rootReducer.group);
    const [loading, setLoading] = useState(false);
    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
 
    const [groupList, setGroupList] = useState({
      code:'',
      group_name:'',
      description:'',
      is_active:'Yes'
      
    })
    const [search, setSearch]= useState({
      is_active:'',
      price:'',
      filterText:'',
      title:'',
        selectAll:false,
        totalSelected:0,
        group:[] as any,
       

    })
    const [errors, setErrors] = useState({
      group_name:'',
      description:'',
    });
    
    const [action, setAction] = useState([

      {code:'rl1', title:'Allow Sending An Email', status:'true'},
      {code:'rl2', title:'Allow Creating and Modifying Client', status:'true'},
      {code:'rl4', title:'Allow Card Pricing Change', status:'true'},
      {code:'rl5', title:'Allow Delete Client', status:'true'},
      {code:'rl6', title:'Allow Delete Card', status:'true'},
      {code:'rl7', title:'Allow Delete Admin', status:'true'},
      {code:'rl8', title:'Allow Card Processing', status:'true'},
      {code:'rl9', title:'Allow Delete Group', status:'true'},
      {code:'rl10', title:'Allow Create and Modify Group', status:'true'},
      

      {code:'rl12', title:'Allow Create and Modify Admin', status:'true'},
      {code:'rl13', title:'Allow Modify Email Template', status:'true'},
      {code:'rl14', title:'Allow Modify Basic Details', status:'true'},
      {code:'rl3', title:'Allow Creating and Modifying Items', status:'true'},
      {code:'rl11', title:'Allow Create and Modify Location Price', status:'true'},
    ]);



    const handleCheckOne =(e:ChangeEvent<HTMLInputElement>)=>{  

      let {name, value} = e.target
  
      const currentContent = action.map((item:any)=>{
                     
          if(item.code ===name){
              return {...item, status:item.status==='false'?'true':'false'}
          }
  
           return item
            })
  
    setSearch({...search, selectAll:false})
     setAction(currentContent) 
          
         }
      
  
   /*  const handleCheckAll =()=>{
      var newContent = [...content]
      for (var i in newContent){
          newContent[i].status=!status.checkAll
      }
      setStatus({...status, checkAll:!status.checkAll})
      setContent(newContent)
   } */

    const handleClear =()=>{
      setSearch({...search, filterText:''});
      setFilterContent(content)
  }



    const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	
      setSearch({...search, [name]:value});

      if(value!==''){
          const filteredItems = content.filter(
              (item:any) => item.code.toLowerCase().includes(value.toLowerCase()) || 
              item.group_name.toLowerCase().includes(value.toLowerCase()) 
          );

          setFilterContent(filteredItems)
      }else{
          setFilterContent(content)
      } 

   }




     const DeleteModal =(row:any)=>{

      let url = ServerUrl+'/user/group/delete/'+row.code;
     
      Swal.fire({
        title: 'Are you really sure?',
        text: "This is very dangerous, you shouldn't do it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showLoaderOnConfirm: true,
      preConfirm: async() => {
        return await axios.get(url, config).then(response=>{
         if(response.data.status!==200){
              throw new Error(response.statusText)
            }
            return response.data
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading(),
        confirmButtonText: 'Yes, I am!'
      }).then((result:any) => {
        if (result.isConfirmed && result.value.status===200) {
         
          let rs =filterContent.filter((item:{id:number})=>item.id !==row.id)
              setContent(rs)
              setFilterContent(rs)
           Swal.fire(
                      'Deleted!',
                      'Record has been deleted.',
                      'success'
                    ) 
        }
      })
    }


const handleEdit =(item:any)=>{
  try{

  
  setGroupList({
    group_name:item.group_name,
   description:item.description,
   is_active:item.is_active,
    code:item.code
   })

   let roles = JSON.parse(item.roles)

   const currentContent = action.map((list:any)=>{  
    return {...list, status:roles.includes(list.code)?'true':'false'}

      })

setAction(currentContent)

  }catch(e){

  }
   
   var doc = document.getElementById('openupdate')
   doc?.click()
}


    const handleSubmit =(event: FormEvent<HTMLButtonElement>, url:string, closeIt:string)=>{
      event.preventDefault();
    
      let error = {...errors}; 
      let formIsValid = true;
    
    let msg ='This field is required';
   

    if(!groupList.group_name){
      error.group_name =msg;
      formIsValid = false;
    } 

    if(!groupList.description){
      error.description =msg;
      formIsValid = false;
    } 


    if(!formIsValid){
      setErrors(error) 
      }
      
      
      if(formIsValid) {
      
        LoadingModal()
        const fd = new FormData();
       let roles = []
        var newMenu = JSON.parse(JSON.stringify(action));
    for (var i in newMenu){
            if(newMenu[i].status==='true'){
            roles.push(newMenu[i].code)
            }
          }
           
             fd.append('group_name', groupList.group_name)
             fd.append('description', groupList.description)
             fd.append('is_active', groupList.is_active)
             fd.append("roles", JSON.stringify(roles, null, 2))
	
            // let url = ServerUrl+'/item/add';
             axios.post(url, fd, config)
             .then(response =>{

             if(response.data.status === 200){
              SuccessModal(response.data.message)
             setGroupList({
              ...groupList,
              group_name:'',
              description:''
             
             })
             var doc = document.getElementById(closeIt)
             doc?.click()
        
             fetchContent()
                   } else{
                   ErrorModal('', response.data.message)
                   }   
                 })
                 .catch((error)=>{
                ErrorModal('oops', error.message)
             
                 }) 
      }
  
  }


    const fetchContent = async()=>{
  LoadingModal()

  let url = ServerUrl+'/user/group/display/all'
  await  axios.get(url, config).then((result:any)=>{
 
           if(Array.isArray(result.data.data) && result.data.data.length!==0){
          
               setFilterContent(result.data.data)
             setContent(result.data.data)
             }
           })
           .catch((error)=>{
               
        ErrorModal('oops', error.message)
           }).finally(()=>{
               closeModal()
           })
           } 


           const handleChangeCat =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            if(name==='is_active'){
              value = value==='No'?'Yes':'No'
            } 
            setGroupList({...groupList, [name]:value});
            setErrors({...errors, [name]:''});
         }
    

         const TableData =()=>{

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40-h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                 {range}  </div>
            
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
                        const columns = [
            
                              
                            {
                              name: 'Name',
                              cell: (row:any) => row.group_name, 
                              sortable: true,
                              reorder:true,
                              wrap:true,
            
                          },
                          {
                            name: 'Description',
                            cell: (row:any) => row.description, 
                            sortable: true,
                            reorder:true,
                            wrap:true,
          
                        },
                            {
                                 name: 'Action',
                                cell: (row:any, index:any, column:any, id:any) => <div className='col'>  
                                   
                                   {group.includes('rl10')? <button type='button' onClick={()=>handleEdit(row)} className="btn btn-info btn-sm "   ><i className='bi bi-pencil'></i> Edit </button>:[]}&nbsp;
            
            
                                     {group.includes('rl9')? <button type='button' className="btn btn-danger btn-sm" onClick={()=>DeleteModal(row)}><i className='bi bi-trash'></i> </button> :[]}
                                
                                </div>,
                                    left:true,
                                          
                
                            },
                        ];
                        
                    
                    
                    
                        return <>
                        
            
                        <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    </>
                    }



            useEffect(()=>{
              fetchContent()
            }, [])

  return (<Template> 

<main id="main" className="main">
<div className="pagetitle">
  <h1>User Groups</h1>
  <nav >
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item active"> Group</li>
    </ol>
  </nav>
</div>






            <section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">All Groups</h5>

<div className="card-body"> 




<div className="modal fade" id="addcategory" tabIndex={-1} aria-modal="true" role="dialog" >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add New Group</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                    <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Group Name<span className="text-danger">*</span></label>
                  <div className="col-sm-10">
                    <input type="text" name="group_name" value={groupList.group_name} onChange={handleChangeCat} className={errors.group_name?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  />
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Description</label>
                  <div className="col-sm-10">



                    <input type="text" name="description" value={groupList.description} onChange={handleChangeCat} className={errors.description?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}    />
                  </div>
                </div>





<h5 className='mt-5'>ACTIVITIES</h5>

<div className="table-responsive">
  <table className='table table-stripped  '>
    <thead>
      <tr>
          <th>Action</th>
          <th>Title</th>
      </tr>
    </thead>
    <tbody>

{action.map((item:any, index:number)=>
      <tr key={index}>
        <td> 
          
          <div className="checkbox-fade fade-in-success">
            <label>
          <input type="checkbox" onChange={handleCheckOne} value={item.status} name={item.code}  checked={item.status==='true'?true:false}   />
              <span className="cr"><i className="cr-icon icofont icofont-ui-check text-success f-14"></i></span> 
            </label>
          </div>
          
          </td>
        <td>{item.title}</td>
      </tr>)}
    </tbody>
  </table>
</div>


                     
                    </div>
                    <div className="modal-footer">
                      <button type="button" id='closeIt' className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={(e)=>handleSubmit(e, ServerUrl+'/user/group/add', 'closeIt')}  >Submit</button>
                    </div>
                  </div>
                </div>
              </div>



              <a  href='#' id='openupdate' data-bs-toggle="modal" data-bs-target="#update-item">

</a>
              <div className="modal fade" id="update-item" tabIndex={-1} aria-modal="true" role="dialog" >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content ">
                    <div className="modal-header">
                      <h5 className="modal-title">Update Group</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                    <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Group Name<span className="text-danger">*</span></label>
                  <div className="col-sm-10">
                    <input type="text" name="group_name" value={groupList.group_name} onChange={handleChangeCat} className={errors.group_name?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  />
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Description<span className="text-danger">*</span></label>
                  <div className="col-sm-10">



                    <input type="text" name="description" value={groupList.description} onChange={handleChangeCat} className={errors.description?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}    />
                  </div>
                </div>


                <div className="form-group row">
		
    <label className="col-sm-2 col-form-label">Is Active</label>
                  
            <div className="col-sm-10 ">
            <div className="checkbox-fade fade-in-success">
            <label>
          <input type="checkbox" value={groupList.is_active} name="is_active" checked={groupList.is_active==='Yes'?true:false} onChange={handleChangeCat} />
              <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span> {groupList.is_active}
            </label>
          </div>
              </div>
          </div>







          <h5 className='mt-5'>ACTIVITIES</h5>

<div className="table-responsive">
  <table className='table table-stripped  '>
    <thead>
      <tr>
          <th>Action</th>
          <th>Title</th>
      </tr>
    </thead>
    <tbody>

{action&&action.map((item:any, index:number)=>
      <tr key={index}>
        <td> 
          
          <div className="checkbox-fade fade-in-success">
            <label>
          <input type="checkbox" onChange={handleCheckOne} value={item.status} name={item.code}  checked={item.status==='true'?true:false}   />
              <span className="cr"><i className="cr-icon icofont icofont-ui-check text-success f-14"></i></span> 
            </label>
          </div>
          
          </td>
        <td>{item.title}</td>
      </tr>)}
    </tbody>
  </table>
</div>

                    </div>
                    <div className="modal-footer">
                      <button type="button" id='close' className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, ServerUrl+'/user/group/update/'+groupList.code, 'close')} >Update</button>
                    </div>
                  </div>
                </div>
              </div>

<div className="row mt-3 mb-3">

<div className=" col-md-8 ">
  
{group.includes('rl10')?<button type="button" className="btn btn-outline-success btn-sm " data-bs-toggle="modal" data-bs-target="#addcategory">
Add New Group
              </button>:[]}


            

  
                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-secondary " >
                          <i className="bi bi-arrow-repeat"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>


<TableData />
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>
                            </main>
    </Template>
  )
}

export default Group