import React, { useState } from 'react'

const Footer =()=> {
  const scrollTop =()=>{
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
}


  const [displayScroll, setDisplayScroll] = useState(false)
    window.addEventListener('scroll', function(event){
        if(this.scrollY>300){
            setDisplayScroll(true)
        }else{
            setDisplayScroll(false)
        }
    })


  return (
   <>
  
<footer id="footer" className="footer">
  <div className="copyright">
   
  <div className="mb-1">© Copyright {new Date().getFullYear()}. All Rights Reserved <br /><b>Eki Digital Mult. Coy. Ltd.</b></div>
  </div>
</footer>
{displayScroll? <a onClick={scrollTop}
href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
:''}

</>


  )
}

export default Footer